import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  get_api_function,
  get_vendor_details,
  get_vendor_list,
  save_machine_time_record,
} from "utils/api";
import {
  update_ticket_status,
  update_workflow_engin,
  get_employees_for_assign,
  return_ticket,
  reopen_ticket,
} from "utils/api";
import { toast } from "react-toastify";
import { Upload, Button as Button1 } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const ActionInTicket = ({ transaction_id, instrutions_list, machine_id }) => {
  const location = useLocation();
  const history_ticket_id = location?.state?.ticket_id
    ? location?.state?.ticket_id
    : localStorage.getItem("history_ticket_id");
  const [vendor_details_rows, Set_vendor_details_rows] = useState([]);
  const success_PendingData_get = (res) => {
    Set_vendor_details_rows(res?.data?.data);
  };

  // ---------------------------Handle Comment and File --------------------------------------------------
  const [comment1, setComment1] = useState("");
  const [fileList1, setFilelist1] = useState([]);

  const handleComments = (e) => {
    setComment1(e.target.value);
  };
  const handleFileList = ({ fileList }) => {
    setFilelist1(fileList);
  };

  // ---------------------------ACTIONS --------------------------------------------------

  const handle_ast_submit = (instructionId) => {
    const ast_formdata = new FormData();
    ast_formdata.append("ticket_id", history_ticket_id);
    ast_formdata.append("comment", comment1);
    ast_formdata.append("attachment", fileList1[0]?.originFileObj);
    ast_formdata.append("attachment1", fileList1[1]?.originFileObj);
    ast_formdata.append("attachment2", fileList1[2]?.originFileObj);
    ast_formdata.append("ticket_transaction_id", transaction_id);
    ast_formdata.append("instruction_id", instructionId);

    // add_employee_working_status(
    //   ast_formdata,
    //   success_add_employee_working_status
    // );
    update_workflow_engin(ast_formdata, success_update_workflow_engin);
  };
  // const success_add_employee_working_status = (res) => {
  //   if (res.status === "success") {
  //     toast.success(res.message);
  //     setTimeout(() => {
  //       // window.location.reload();
  //     }, 1000);
  //   } else if (res.status === "failed") {
  //     toast.warning(res.message);
  //   }
  // };
  // --------------------------------------
  const handleCloseTicket = (ticket_id_to_be_closed) => {
    const closePostData = new FormData();
    closePostData.append("comment", comment1);
    closePostData.append("attachment", fileList1[0]?.originFileObj);
    closePostData.append("attachment1", fileList1[1]?.originFileObj);
    closePostData.append("attachment2", fileList1[2]?.originFileObj);
    closePostData.append("ticket_id", ticket_id_to_be_closed);
    update_ticket_status(closePostData, success_update_ticket_status);
  };

  const success_update_ticket_status = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (res.status === "failed") {
      set_submit_disabled(false);
      toast.warning(res.message);
    }
  };

  // -----------------------------------------------------------------
  const handleApprove = () => {
    const approvePostData = new FormData();
    approvePostData.append("comment", comment1);
    approvePostData.append("attachment", fileList1[0]?.originFileObj);
    approvePostData.append("attachment1", fileList1[1]?.originFileObj);
    approvePostData.append("attachment2", fileList1[2]?.originFileObj);
    approvePostData.append("instruction_id", 1);
    approvePostData.append("ticket_transaction_id", transaction_id);

    update_workflow_engin(approvePostData, success_update_workflow_engin);
  };

  const handleDecline = () => {
    const declinePostData = new FormData();
    declinePostData.append("instruction_id", 5);
    declinePostData.append("ticket_transaction_id", transaction_id);
    declinePostData.append("comment", comment1);
    declinePostData.append("attachment", fileList1[0]?.originFileObj);
    declinePostData.append("attachment1", fileList1[1]?.originFileObj);
    declinePostData.append("attachment2", fileList1[2]?.originFileObj);
    update_workflow_engin(declinePostData, success_update_workflow_engin);
  };
  const success_update_workflow_engin = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (res.status === "failed") {
      set_submit_disabled(false);
      toast.warning(res.message);
    }
  };
  // ----------------------------------------------------------------------

  const handleReopen = () => {
    const reopenPostData = new FormData();
    reopenPostData.append("comment", comment1);
    reopenPostData.append("attachment", fileList1[0]?.originFileObj);
    reopenPostData.append("attachment1", fileList1[1]?.originFileObj);
    reopenPostData.append("attachment2", fileList1[2]?.originFileObj);
    reopenPostData.append("ticket_id", history_ticket_id);
    reopen_ticket(reopenPostData, success_handle_Reopen_Return);
  };

  const handleReturn = () => {
    const returnPostData = new FormData();

    returnPostData.append("comment", comment1);
    returnPostData.append("attachment", fileList1[0]?.originFileObj);
    returnPostData.append("attachment1", fileList1[1]?.originFileObj);
    returnPostData.append("attachment2", fileList1[2]?.originFileObj);
    returnPostData.append("transaction_id", transaction_id);
    // returnPostData.append("ticket_id", history_ticket_id);
    return_ticket(returnPostData, success_handle_Reopen_Return);
  };

  const success_handle_Reopen_Return = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (res.status === "failed") {
      set_submit_disabled(false);
      toast.warning(res.message);
    }
  };
  // ----------------------------------------------------------------------

  useEffect(() => {
    transaction_id &&
      get_employees_for_assign(success_get_employees_for_assign, {
        transacation_id: transaction_id,
      });
    get_api_function("/pm/get_manufacturer_list", success_PendingData_get);
  }, [transaction_id]);

  const [employees_list, set_employees_list] = useState([]);

  const success_get_employees_for_assign = (res) => {
    set_employees_list(res.data.ticket_status);
    set_dropdown_list(res.data.ticket_status);
  };

  const handle_assign_user = (e) => {
    const assignPostData = new FormData();
    assignPostData.append("comment", comment1);
    assignPostData.append("attachment", fileList1[0]?.originFileObj);
    assignPostData.append("attachment1", fileList1[1]?.originFileObj);
    assignPostData.append("attachment2", fileList1[2]?.originFileObj);
    assignPostData.append("employee_id", user_id);
    assignPostData.append("instruction_id", 2);
    assignPostData.append("ticket_transaction_id", transaction_id);
    assignPostData.append(
      "employee_type",
      radio_value === "vendor" ? "Vendor" : "Employee"
    );
    update_workflow_engin(assignPostData, success_update_workflow_engin);
  };

  const [user_id, set_user_id] = useState("");

  const [selectedAction, set_selectedAction] = useState("i");
  const handle_action_select = (e) => {
    set_selectedAction(e.target.value);
    set_submit_disabled(false);
  };

  const [submit_disabled, set_submit_disabled] = useState(true);

  const handlePause = () => {
    const time = dayjs.utc().add(5, "hour").add(30, "minute");
    save_machine_time_record(() => {}, {
      ticket_id: history_ticket_id,
      machine_id: machine_id,
      end_time: time,
    });
  };
  const handleActionSubmit = (e) => {
    set_submit_disabled(true);
    e.preventDefault();
    switch (selectedAction) {
      case 1:
        handleApprove();
        // Approve
        break;

      case 2:
        handle_assign_user();
        // Approve & Assign
        break;

      case 3:
        // Initiate
        break;

      case 4:
        handle_ast_submit(selectedAction);
        // Completed
        break;

      case 5:
        handleDecline();
        // Reject
        break;

      case 6:
        handle_ast_submit(selectedAction);
        // Acknowledge
        break;

      case 7:
        handle_ast_submit(selectedAction);
        // Waiting For Spare Parts
        break;

      case 8:
        handleCloseTicket(history_ticket_id);
        machine_id && handlePause();
        // Close
        break;

      case 9:
        handleReturn();
        // Return
        break;

      case 10:
        // Reopen
        handleReopen();
        break;

      case 11:
        handle_ast_submit(selectedAction);
        // In-Progress
        break;

      case 12:
        handle_ast_submit(selectedAction);
        // Pending
        break;

      case 13:
        handle_ast_submit(selectedAction);
        // Verified
        break;

      case 14:
        handle_ast_submit(selectedAction);
        // others
        break;

      case 15:
        handle_ast_submit(selectedAction);
        // Re-Initiate
        break;

      default:
        toast.warning("Action is not selected!");
        break;
    }
    return true;
  };

  const [radio_value, set_radio_value] = useState("employee");
  const [dropdown_list, set_dropdown_list] = useState([]);

  const handle_radio_value = (event) => {
    set_radio_value(event.target.value);
    if (event.target.value === "employee") {
      set_dropdown_list(employees_list);
    } else {
      set_dropdown_list(
        vendor_details_rows?.map((item) => {
          return {
            employee_name: item?.manufacturer_name,
            employee_id: item?.manufacturer_id,
          };
        })
      );
    }
  };

  return (
    <form onSubmit={handleActionSubmit}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center">
          <InputLabel htmlFor="ticketType" sx={{ fontSize: "16px" }}>
            Select Action: &nbsp;&nbsp;&nbsp;
          </InputLabel>
          <Select
            labelId="ticketType"
            value={selectedAction}
            onChange={handle_action_select}
            size="small" // value={}
            fullwidth
            sx={{ width: "50%" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
            required
          >
            {instrutions_list?.map((item) => {
              return (
                <MenuItem value={item.instruction_id}>
                  {item.instruction_name}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
        {selectedAction === 2 && (
          <Stack>
            <RadioGroup
              value={radio_value}
              onChange={handle_radio_value}
              sx={{ dispaly: "flex", flexDirection: "row" }}
            >
              <FormControlLabel
                value="employee"
                control={<Radio />}
                label="Employee"
              />
              <FormControlLabel
                value="vendor"
                control={<Radio />}
                label="Vendor"
              />
            </RadioGroup>
          </Stack>
        )}
        {selectedAction === 2 && (
          <Select
            labelId="department"
            defaultValue="i"
            onChange={(e) => {
              set_user_id(e.target.value);
            }}
            required
          >
            <MenuItem disabled value="i">
              <em>
                Select {`${radio_value === "employee" ? "User" : "Vendor"}`}
              </em>
            </MenuItem>
            {radio_value &&
              dropdown_list.map((emp, userIndex) => (
                <MenuItem key={emp.employee_id} value={emp.employee_id}>
                  {emp?.company_name
                    ? `${emp.employee_name + " - " + emp.company_name}`
                    : emp?.employee_name}
                </MenuItem>
              ))}
          </Select>
        )}
        <OutlinedInput
          placeholder="Comments"
          type="text"
          multiline
          minRows={2}
          size="small"
          onChange={handleComments}
          required
        />
        <Upload maxCount={3} onChange={handleFileList}>
          <Button1 icon={<UploadOutlined />}>Add Attachment</Button1>
        </Upload>
        <Stack spacing={2} width="50%">
          <Button disabled={submit_disabled} variant="contained" type="submit">
            Confirm
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ActionInTicket;
