import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  Upload,
  Modal,
  Col,
  Row,
  Image as Image1,
  Select,
} from "antd";
import { Stack, Grid } from "@mui/material";
import MainCard from "components/MainCard";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  set_about_us,
  set_company_logo,
  set_company_name,
  set_company_url,
  set_privacy_policy,
  set_terms_conditions,
  set_theme_color,
} from "store/reducers/company_profile";
import { add_company_profile } from "utils/api";
import { toast } from "react-toastify";

const { Option } = Select;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const CompanyProfileForm = () => {
  const [access_list_data, setaccess_list_data] = useState([]);

  useEffect(() => {
    setaccess_list_data(JSON.parse(localStorage.getItem("access_list_data")));
  }, []);

  const dispatch = useDispatch();
  const {
    company_name,
    company_url,
    company_logo,
    theme_color,
    privacy_policy,
    terms_conditions,
    about_us,
  } = useSelector((state) => state.company_profile);

  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handlePreview = async (file) => {
    console?.log(file);
    if (!file?.url && !file?.preview) {
      file.preview = await getBase64(file?.originFileObj);
    }
    setPreviewImage(file?.url || file?.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file?.name || file?.url?.substring(file?.url?.lastIndexOf("/") + 1)
    );
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const [url_type, set_url_type] = useState("https://");
  const selectBefore = (
    <Select value={url_type} onChange={(value) => set_url_type(value)}>
      <Option value="https://">https://</Option>
      <Option value="http://">http://</Option>
    </Select>
  );
  const [company_name_data, set_company_name_data] = useState("");
  const [company_url_data, set_company_url_data] = useState("");
  const [company_privacy, set_company_privacy] = useState("");
  const [company_terms, set_company_terms] = useState("");
  const [company_about, set_company_about] = useState("");
  const [company_logo_data, set_company_logo_data] = useState("");
  const [company_theme_color, set_company_theme_color] = useState("");

  const reset_function = () => {
    set_company_name_data("");
    set_company_url_data("");
    set_company_privacy("");
    set_company_terms("");
    set_company_about("");
    set_company_logo_data("");
    set_company_theme_color("");
  };

  const success = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      reset_function();
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  return (
    <Stack sx={{ px: "10%" }}>
      <MainCard title="Company Profile">
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
        >
          <Grid container spacing={1}>
            <Grid container item lg={8}>
              <Grid item lg={12}>
                <Form.Item label="Company Name" size="large">
                  <Input
                    onChange={(e) => {
                      dispatch(set_company_name(e.target.value));
                      set_company_name_data(e.target.value);
                    }}
                  />
                </Form.Item>
              </Grid>
              <Grid item lg={12}>
                <Form.Item label="Company URL" size="large">
                  <Input
                    addonBefore={selectBefore}
                    onChange={(e) => {
                      dispatch(set_company_url(url_type + e.target.value));
                      set_company_url_data(url_type + e.target.value);
                    }}
                  />
                </Form.Item>
              </Grid>
              <Grid item lg={12}>
                <Form.Item label="Privacy Policy">
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={async ({ fileList: newFileList }) => {
                      set_company_privacy(newFileList[0]?.originFileObj);
                      if (newFileList?.length > 0) {
                        newFileList[0].preview = await getBase64(
                          newFileList[0]?.originFileObj
                        );
                      }
                      dispatch(
                        set_privacy_policy(
                          newFileList[0]?.url || newFileList[0]?.preview
                        )
                      );
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </Grid>
              <Grid item lg={12}>
                <Form.Item label="Terms & Conditions">
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={async ({ fileList: newFileList }) => {
                      set_company_terms(newFileList[0]?.originFileObj);
                      if (newFileList?.length > 0) {
                        newFileList[0].preview = await getBase64(
                          newFileList[0]?.originFileObj
                        );
                      }
                      dispatch(
                        set_terms_conditions(
                          newFileList[0]?.url || newFileList[0]?.preview
                        )
                      );
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </Grid>
              <Grid item lg={12}>
                <Form.Item label="Contact Us">
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={async ({ fileList: newFileList }) => {
                      set_company_about(newFileList[0]?.originFileObj);
                      if (newFileList?.length > 0) {
                        newFileList[0].preview = await getBase64(
                          newFileList[0]?.originFileObj
                        );
                      }
                      dispatch(
                        set_about_us(
                          newFileList[0]?.url || newFileList[0]?.preview
                        )
                      );
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </Grid>
              <Grid item lg={12}>
                <Form.Item
                  label="Company Logo"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  tooltip="Preferred Logo size: (536px, 276px), Format: PNG"
                >
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={async ({ fileList: newFileList }) => {
                      set_company_logo_data(newFileList[0]?.originFileObj);
                      if (newFileList?.length > 0) {
                        newFileList[0].preview = await getBase64(
                          newFileList[0]?.originFileObj
                        );
                      }
                      setFileList(newFileList);
                      setPreviewImage(
                        newFileList[0]?.url || newFileList[0]?.preview
                      );
                      dispatch(
                        set_company_logo(
                          newFileList[0]?.url || newFileList[0]?.preview
                        )
                      );
                    }}
                    maxCount={1}
                    beforeUpload={() => false}
                  >
                    {fileList.length !== 0 ? null : uploadButton}
                  </Upload>
                </Form.Item>
              </Grid>
              <Grid item lg={12}>
                <Form.Item label="Theme Color">
                  <ColorPicker
                    showText={(color) => (
                      <span>{color.toHexString()?.toUpperCase()}</span>
                    )}
                    onChange={(color, hex) => {
                      dispatch(set_theme_color(hex));
                      set_company_theme_color(hex);
                    }}
                  />
                </Form.Item>
              </Grid>
              <Grid item lg={6}>
                <Stack direction="row" justifyContent="end" spacing={2}>
                  <Button onClick={() => window.location.reload()}>
                    Clear
                  </Button>
                  {access_list_data?.find(
                    (item) => item.module_name === "Company Details"
                  )?.create_access &&
                    access_list_data?.find(
                      (item) => item.module_name === "Company Details"
                    )?.update_access && (
                      <Button
                        type="primary"
                        disabled={
                          !(
                            Boolean(company_name_data) &&
                            Boolean(company_logo_data)
                          )
                        }
                        onClick={() => {
                          let formData = new FormData();
                          formData.append("company_name", company_name_data);
                          formData.append("company_url", company_url_data);
                          formData.append("company_logo", company_logo_data);
                          formData.append("company_policy", company_privacy);
                          formData.append(
                            "company_Terms_and_condition",
                            company_terms
                          );
                          formData.append("company_contact_us", company_about);
                          formData.append("theme_color", company_theme_color);
                          formData.append("admin_name", "Admin A");
                          formData.append("admin_email", "admin@gmail.com");
                          formData.append("company_discription", "");

                          add_company_profile(success, formData);
                        }}
                      >
                        Submit
                      </Button>
                    )}
                </Stack>
              </Grid>
            </Grid>
            {company_logo && (
              <Grid container item lg={4}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Image1 width={400} height="fit-content" src={company_logo} />
                </Stack>
              </Grid>
            )}
          </Grid>
        </Form>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
      </MainCard>
    </Stack>
  );
};
export default CompanyProfileForm;
